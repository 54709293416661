import {configure, makeObservable, observable, reaction, computed} from 'mobx';
import React from 'react';

import http from 'http.js';
import {PROFILE_CONTACT_TYPE} from 'core/constants.js';
import ContactModalV2 from 'core/components/v2/ContactModalV2.js';
import {ModalV2} from '@HealthShareAU/hs-component-library';

import PracticeStoreV2 from 'practices/stores/PracticeStoreV2.js';

// Throws if observable is changed outside of action
configure({enforceActions: 'observed', safeDescriptors: false});

export default class ProfileStoreV2 extends PracticeStoreV2 {
    constructor(rootStore) {
        super();

        this.rootStore = rootStore;

        makeObservable(this, {
            callNowModalIsOpen: observable,
            callNowModalActiveItem: observable,
            customTextLoaded: observable,
            feeInfo: observable,
            fundInfo: observable,
            locationSectionActiveItemIdx: observable,

            contactProps: computed,
            customTextConsultationFee: computed,
            customTextProcedureFee: computed,
            locationSectionActiveItemIdx: observable,
            locationSectionChipsData: computed,
            hasFees: computed,
            header: computed,
            isSpecialist: computed,
            relatedFactsheets: computed,
            shouldShowLocationsTab: computed,
        });

        reaction(
            () => this.rootStore?.healthFundStore.healthFund?.id,
            () => this.loadHealthFundCustomText(),
        );
    }
    callNowModalIsOpen = false;
    callNowModalActiveItem = {};
    customTextLoaded = false;
    feeInfo = null;
    fundInfo = null;
    healthFundData = {};
    healthFundDataLoaded = false;
    shouldFetchRelatedContent = false;
    shouldFetchFAQs = false;
    avatarPlaceholderIcon = 'user';
    bookingTabTitle = 'Practices';
    locationSectionActiveItemIdx = 0;

    // static member
    internalTrackingEvent = {
        faxClick: {
            event: 'practitionerFaxClick',
        },
    };

    feeInfoKeys = [
        'NOT_INSURED_CONSULTATION_FEES_INFO',
        'INSURED_CONSULTATION_FEES_INFO',
        'NOT_INSURED_PROCEDURE_FEES_INFO',
        'INSURED_PROCEDURE_FEES_INFO',
        'PRACTITIONER_FEE_COMMENTS_TOOLTIP',
    ];

    fundInfoKeys = [
        'GAP_SCHEME_GRAPH_TITLE',
        'GAP_SCHEME_PARTICIPATION_TOOLTIP',
        'GAP_SCHEME_PARTICIPATION_TOOLTIP_PRO',
    ];

    get locationSectionChipsData() {
        const {
            address,
            fax,
            phoneNumbers,
            website,
        } = this.locationSectionActiveItem;
        return {
            practiceLocationAddress: address,
            phones: phoneNumbers.map((phone) => {
                return {...phone, number: phone.formattedNumber};
            }),
            fax: {...fax, number: fax.formattedNumber},
            externalUrl: website,
        };
    }

    get contactProps() {
        const {practice: profile} = this;
        if (this.practiceLoaded && profile.locations?.length > 0) {
            return {
                contactId: profile.id,
                contactName: profile.displayName,
                contactType: PROFILE_CONTACT_TYPE,
                isEnhanced: profile.enhanced,
                items: [
                    {
                        address: this.locationSectionActiveItem.address,
                        id: this.locationSectionActiveItem.id,
                        name: this.locationSectionActiveItem.name,
                        phones: this.locationSectionActiveItem.phoneNumbers.map(
                            (phone) => ({
                                ...phone,
                                number: phone.formattedNumber,
                            }),
                        ),
                        type: PROFILE_CONTACT_TYPE,
                    },
                ],
            };
        }
        return null;
    }

    get locationSectionActiveItem() {
        return this.practice.locations?.[this.locationSectionActiveItemIdx];
    }
    get customTextConsultationFee() {
        if (!this.feeInfo) {
            return;
        }
        return this.customTextLoaded &&
            this.rootStore.healthFundStore.healthFund?.id
            ? this.feeInfo?.INSURED_CONSULTATION_FEES_INFO
            : this.feeInfo?.NOT_INSURED_CONSULTATION_FEES_INFO;
    }
    get customTextProcedureFee() {
        if (!this.feeInfo) {
            return;
        }
        return this.customTextLoaded &&
            this.rootStore.healthFundStore.healthFund?.id
            ? this.feeInfo?.INSURED_PROCEDURE_FEES_INFO
            : this.feeInfo?.NOT_INSURED_PROCEDURE_FEES_INFO;
    }

    get hasFees() {
        return true;
    }

    get shouldShowLocationsTab() {
        return this.practiceLoaded && this.practice.locations?.length > 0;
    }

    get header() {
        const {practice: profile} = this;
        return {
            avatar: profile.avatar,
            amountOfLocations: 0,
            languagesSpoken: profile.languagesSpoken,
            name: profile.displayName,
            website: {href: profile.website},
        };
    }

    get relatedFactsheets() {
        return this.practiceLoaded && this.practice.relatedInfo?.factsheets;
    }

    get isSpecialist() {
        return !!this.practice.specialties?.find(
            (specialty) => specialty.isSpecialist,
        );
    }

    trackPageOpen() {
        // Reinstate practitionerDetail internal event tracking
    }

    parseCustomTextKeys(keys, text) {
        const info = {};
        for (const key of keys) {
            info[key] = text[key];
        }
        return info;
    }

    async loadHealthFundCustomText() {
        const url = `/api/core/v1/custom-text/`;
        const keys = this.feeInfoKeys.concat(this.fundInfoKeys);
        const customTextResponse = await http.get({url, data: {key: keys}});
        if (customTextResponse) {
            const feeInfo = this.parseCustomTextKeys(
                this.feeInfoKeys,
                customTextResponse,
            );
            const fundInfo = this.parseCustomTextKeys(
                this.fundInfoKeys,
                customTextResponse,
            );
            this.updateStore({
                feeInfo,
                fundInfo,
                customTextLoaded: true,
            });
        }
    }

    getRequestApptModalContent = (location) => {
        const {
            specialties,
            practice: {avatar, displayName},
        } = this;
        const {
            address,
            locality: {id: localityId, name: localitySb},
            id,
        } = location;
        return {
            address,
            avatar,
            displayName,
            displayScreenerQuestion: this.isSpecialist,
            id,
            specialties,
            localityId,
            localitySb,
        };
    };

    getContactModalContent = (location) => {
        const {
            contactProps,
            callNowModalActiveItem,
            rootStore: {
                paramStore: {hasSkin},
                healthFundStore: {healthFundThemeColor, healthFund},
            },
        } = this;
        const colorTheme =
            hasSkin && healthFundThemeColor ? healthFundThemeColor : null;
        if (!location) {
            location = callNowModalActiveItem;
        }
        const {id, address, name, type, phoneNumbers} = location;
        return {
            ...contactProps,
            showSingleNumber: true,
            colorTheme,
            hasSkin,
            healthFundId: healthFund?.id,
            items: [
                {
                    id,
                    address,
                    name,
                    phones: phoneNumbers.map((phone) => ({
                        ...phone,
                        number: phone.formattedNumber,
                    })),
                    type,
                },
            ],
        };
    };

    showCallNow = (callNowModalActiveItem) => {
        this.updateStore({callNowModalActiveItem, callNowModalIsOpen: true});
    };

    renderCallNowModal = () => {
        const {
            callNowModalIsOpen,
            rootStore: {
                paramStore: {hasSkin},
                healthFundStore: {healthFundThemeColor},
            },
        } = this;
        const colorTheme =
            hasSkin && healthFundThemeColor ? healthFundThemeColor : null;
        return (
            callNowModalIsOpen && (
                <ModalV2
                    isOpen={callNowModalIsOpen}
                    colorTheme={colorTheme}
                    handleClose={() =>
                        this.updateStore({callNowModalIsOpen: false})
                    }
                >
                    <ContactModalV2 {...this.getContactModalContent()} />
                </ModalV2>
            )
        );
    };
}
